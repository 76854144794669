import { render, staticRenderFns } from "./Email.vue?vue&type=template&id=1af1a6ef"
import script from "./Email.vue?vue&type=script&lang=js"
export * from "./Email.vue?vue&type=script&lang=js"
import style0 from "./Email.vue?vue&type=style&index=0&id=1af1a6ef&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports